<template>
    <CModal :title="title" color="danger" :show.sync="clearModal">   
        <p style="font-weight: bold;">¿Estás seguro que deseas eliminar los todos productos?</p>
        <br>
        Todos los productos serán eliminado de la base de datos y tendrás que seleccionar nuevos si quieres seguir facturando.

        <template #header>
          <h5 class="modal-title">{{title}}</h5>
          <CButtonClose @click="clearModal = false" class="text-white"/>
        </template>
        <template #footer>
          <CButton @click="clearModal = false" color="secondary">Cancelar</CButton>
          <CButton @click="destroy" color="danger">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
export default {
  name: 'ClearProductsModal',
  props: {

  },
  data () {
    return {
      clearModal: false,
      title: "Eliminar Registro",
      id: 0,
      name: 'Registro'
    }
  },
  methods: {
    show (title, id, name) {
      this.clearModal = true;
      this.title = title;
      this.id = id;
      this.name = name;
    },
    destroy () {
      this.clearModal = false;
      this.$emit("delete", this.id );
    }
  }
}
</script>