<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="productModal"
    >   
        <CForm>
          <CRow>
            <CCol md="6">
              <CSelect
                label="Tipo: "
                :options="typesOptions"
                :value.sync="$v.form.type_id.$model"
                placeholder="Selecciona un tipo"
                @change="changeType"
              />
            </CCol>
            <CCol md="6">
              <CSelect
                label="Segmento: "
                :options="segmentsOptions"
                :value.sync="$v.form.segment_id.$model"
                placeholder="Selecciona un segmento"
                @change="changeSegment"
                />
            </CCol>
          </CRow>
          <CRow>
            <CCol md="6">
              <CSelect
                label="Familia: "
                :options="familiesOptions"
                :value.sync="$v.form.family_id.$model"
                placeholder="Selecciona una familia"
                @change="changeFamily"
              />
            </CCol>
            <CCol md="6">
              <CSelect
                label="Clase: "
                :options="classesOptions"
                :value.sync="$v.form.class_id.$model"
                placeholder="Selecciona una clase"
                @change="changeClass"
                />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="9">
              <CInput
                label="Buscador:"
                :lazy="false"
                :value.sync="$v.form.search.$model"
                placeholder=""
                autocomplete="search"
              />
            </CCol>
            <CCol sm="3" style="padding-top: 30px !important; text-align: right;">
              <CButton @click="search" :color="color">Buscar</CButton>
            </CCol>
          </CRow>
          <CRow v-if="items.length > 0">
            <CCol md="12">
              <CDataTable
                hover
                border
                fixed
                :items="items"
                :fields="fields"
                :items-per-page="30"
                pagination
              >
                <template #code="{item}"><td class="w100 text-center">{{item.code}}</td></template>
                <template #actions="{item}">
                  <td class="table_actions">
                    <CSwitch
                        v-model="item.selected"
                        :checked.sync="item.selected"
                        class="mx-1"
                        color="success"
                        size="lg"
                        name="switch1"
                        @update:checked="selectProduct(item)"
                      />
                  </td>
                </template>
              </CDataTable>
            </CCol>
          </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="success">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, decimal} from "vuelidate/lib/validators"
import fiscal_ws from '../../../services/fiscal-products';
import store from '../../../store';

export default {
  name: 'ProductModal',
  props: {

  },
  data () {
    return {
      productModal: false,
      title: "Productos y Servicios",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      types: [],
      typesOptions: [],
      segments: [],
      segmentsOptions: [],
      families: [],
      familiesOptions: [],
      classes: [],
      classesOptions: [],
      fields: [
        {key: 'code', label: 'Código SAT'},
        {key: 'name', label: 'Nombre'},
        {key: 'actions', label: '', class: 'actions' }
      ],
      items: [],
      selections: false
    }
  },
  mounted: async function() {
    let response = await fiscal_ws.getTypes(); 

    if(response.type == "success"){
      this.types = response.data;
      this.typesOptions = await this.$parseSelectOptionsOrdered(this.types, "id", "name");
    }

    this.segments = [];
  },
  methods: {
    async changeType() {
      this.segments = [];
      this.form.segment_id = 0;

      this.families = [];
      this.familiesOptions = [];
      this.classes = [];
      this.classesOptions = [];

      if(this.form.type_id > 0){
        let response = await fiscal_ws.getSegments(this.form.type_id); 

        if(response.type == "success"){
          this.segments = response.data;
          this.segmentsOptions = await this.$parseSelectOptionsOrdered(this.segments, "id", "name");
        }
      }
    },
    async changeSegment() {
      this.families = [];
      this.form.family_id = 0;

      this.classes = [];
      this.classesOptions = [];

      if(this.form.type_id > 0 && this.form.segment_id > 0){
        let response = await fiscal_ws.getFamilies(this.form.type_id, this.form.segment_id); 

        if(response.type == "success"){
          this.families = response.data;
          this.familiesOptions = await this.$parseSelectOptionsOrdered(this.families, "id", "name");
        }
      }
    },
    async changeFamily() {
      this.classes = [];
      this.form.class_id = 0;

      if(this.form.type_id > 0 && this.form.segment_id > 0 && this.form.family_id > 0){
        let response = await fiscal_ws.getClasses(this.form.type_id, this.form.segment_id, this.form.family_id); 

        if(response.type == "success"){
          this.classes = response.data;
          this.classesOptions = await this.$parseSelectOptionsOrdered(this.classes, "id", "name");
        }
      }
    },
    async changeClass() {
      this.items = [];
      if(this.form.type_id > 0 && this.form.segment_id > 0 && this.form.family_id > 0 && this.form.class_id > 0){
        let response = await fiscal_ws.getProducts(this.form.type_id, this.form.segment_id, this.form.family_id, this.form.class_id); 

        if(response.type == "success"){
          this.items = response.data;

          this.items.forEach(function(item){
            item.selected = item.selected > 0 ? true : false;
          });
        }
      }
    },
    async search () {
      this.items = [];
      
      let response = await fiscal_ws.getSelectedProducts(this.form.search, this.form.type_id, this.form.segment_id, this.form.family_id, this.form.class_id); 

      if(response.type == "success"){
        this.items = response.data;

        this.items.forEach(function(item){
          item.selected = item.selected > 0 ? true : false;
        });
      }
      else{
        this.showToast(response.type, response.message);
      }
    },
    async selectProduct (item) {
      this.selections = true;

      if(item.selected){
        let response = await fiscal_ws.addSelectedProduct(item.code); 

        this.$emit("store", this.form );

        this.showToast(response.type, response.message);
      }
      else{
        let response = await fiscal_ws.deleteSelectedProduct(item.code); 

        this.$emit("store", this.form );

        this.showToast(response.type == "success" ? "danger" : response.type, response.message);
      }
    },
    storeModal () {
      this.productModal = true;
      this.color = "info";
      this.title = "Productos y Servicios";
      this.isEdit = false;
      this.submitted = false;
      this.selections = false;

      this.items = [];
      this.form = this.getEmptyForm();
    },
    closeModal () {
      this.productModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          type_id: 0,
          segment_id: 0,
          family_id: 0,
          class_id: 0,
          search: ''
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      type_id: {},
      segment_id: {},
      family_id: {},
      class_id: {},
      search: {},
      code: {
        required,
        minLength: minLength(2)
      },
      name: {
        required,
        minLength: minLength(2)
      },
      type_id: {},
      description: {},
      cost: {
        required,
        decimal
      },
      price: {
        required,
        decimal
      },
      minimal_stock: {
        required,
        decimal
      },
      observations: {},
    }
  },
}
</script>